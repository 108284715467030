
// Redefine the colors used for events
$event-color-red : #D50000;
$event-color-pink : #C51162;
$event-color-purple : #AA00FF;
$event-color-violet : #6200EA;
$event-color-indigo : #304FFE;
$event-color-blue : orange;
$event-color-cyan : #00B8D4;
$event-color-teal : #00BFA5;
$event-color-green : #00C853;
$event-color-lime : #AEEA00;
$event-color-yellow : #FFD600;
$event-color-orange : #FF6D00;

// Customize events a bit more
$event-font-weight : 400;
$event-font-size : 13px;

// Customize headers etc
$grid-header-border-color : #666;
$grid-cell-border-color : #AAA;
$grid-header-background-color : #474849;
$grid-header-text-color : pink;
$grid-header-font-weight : 400;

$icon-font : 'Font Awesome 5 Free';
$icon-font-weight : 900;

//$dark-gray: #1e272e;
$dark-gray: lighten(#2b343b, 10%);

// Buttons in the demo header are hardcoded to be blue, change it
$blue : #106c12;

// This path will need to be adjusted if your project structure is different
$fa-font-path : '../../../fonts';

// Extend the classic theme
@import '~@bryntum/schedulerpro/source/resources/sass/themes/classic-dark.scss';

// Override the theme-info rule with our theme info
.b-theme-info:before {
  content : '{"name":"Custom"}';
}

.b-tree-icon {
  color: #2b343b;
}

.b-icon-tree-expand, .b-icon-tree-collapse {
  color: #2b343b;
}

.b-focused {
  border-color: #106c12;
}
